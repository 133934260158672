<template>
  <div id="app">
    <home />
  </div>
</template>

<script>
import home from './views/Home.vue'

export default {
  name: 'app',
  components: {
    home
  },
  mounted() {
    this.$nextTick(() => {
			document.getElementById('title').innerHTML = '全国电子印章管理与服务平台'+ this.$areaName +'平台'
		})
  }
}
</script>

<style>
/*@import url("https://fonts.googleapis.com/css?family=Noto+Sans+SC:100,300,400,500,700,900");*/
#app {
  font-family: 'Noto Sans SC', "Microsoft YaHei", "黑体", "宋体", Helvetica, Arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  font-size: 14px;
  line-height: 180%;
  color: #2c3e50;
}
input{
	font-family: 'Noto Sans SC', "Microsoft YaHei", "黑体", "宋体", Helvetica, Arial, sans-serif;
}
</style>

const provinceGeo = {
    "110000": "北京市",
    "120000": "天津市",
    "130000": "河北省",
    "140000": "山西省",
    "150000": "内蒙古自治区",
    "210000": "辽宁省",
    "220000": "吉林省",
    "230000": "黑龙江省",
    "310000": "上海市",
    "320000": "江苏省",
    "330000": "浙江省",
    "340000": "安徽省",
    "350000": "福建省",
    "360000": "江西省",
    "370000": "山东省",
    "410000": "河南省",
    "420000": "湖北省",
    "430000": "湖南省",
    "440000": "广东省",
    "450000": "广西壮族自治区",
    "460000": "海南省",
    "500000": "重庆市",
    "510000": "四川省",
    "520000": "贵州省",
    "530000": "云南省",
    "540000": "西藏自治区",
    "610000": "陕西省",
    "620000": "甘肃省",
    "630000": "青海省",
    "640000": "宁夏回族自治区",
    "650000": "新疆维吾尔族自治区",
    "710000": "台湾省",
    "810000": "香港特别行政区",
    "820000": "澳门特别行政区",
    "830000": "生产建设兵团",
}

const realmNameDict = {
    "anh": "340000",    //  安徽
    "bingt": "830000",  //  兵团
    "bj": "110000",     //  北京
    "cq": "500000",     //  重庆
    "gd": "440000",     //  广东
    "gs": "620000",     //  甘肃
    "gx": "450000",     //  广西
    "gzh": "520000",    //  贵州
    "hain": "460000",   //  海南
    "hb": "420000",     //  湖北
    "heb": "130000",    //  河北
    "hen": "410000",    //  河南
    "hlj": "230000",    //  黑龙江
    "hun": "430000",     // 湖南
    "jl": "220000",      // 吉林
    "js": "320000",      // 江苏
    "jx": "360000",      // 江西
    "ln": "210000",      // 辽宁
    "nm": "150000",      // 内蒙古
    "nx": "640000",     // 宁夏
    "qh": "630000",      // 青海
    "sc": "510000",      // 四川
    "shd": "370000",    //  山东
    "shh": "310000",    // 上海
    "shx": "610000",    // 陕西
    "sx": "140000",      // 山西
    "tj": "120000",      // 天津
    "xj": "650000",      // 新疆
    "xz": "540000",      // 西藏
    "yn": "530000",      // 云南
    "zhj": "330000",   //  浙江
    "fj": "350000",   //  福建
}

const beianDict = {
    "510000": "蜀ICP备2023020519号",      // 四川
    "440000": "蜀ICP备2023020519号",     //  广东
    "320000": "蜀ICP备2023020519号",      // 江苏
    "500000": "蜀ICP备2023020519号",     //  重庆
    "450000": "蜀ICP备2023020519号",      // 广西
    "330000": "蜀ICP备2023020519号",     //  浙江
    "610000": "蜀ICP备2023020519号",      // 陕西
    "140000": "蜀ICP备2023020519号",     //  山西
    "130000": "蜀ICP备2023020519号",     //  河北
    "420000": "蜀ICP备2023020519号",     //  湖北
    "360000": "蜀ICP备2023020519号",     //  江西
    "340000": "蜀ICP备2023020519号",     //  安徽
    "350000": "蜀ICP备2023020519号",     //  福建
    "460000": "蜀ICP备2023020519号",     //  海南
    "430000": "蜀ICP备2023020519号",     //  湖南
    "410000": "蜀ICP备2023020519号",     //  河南
}

module.exports = { provinceGeo, realmNameDict, beianDict}
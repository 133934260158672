<template>
<div>
	<div class="w service">
		<div class="item apply">
			<div>
				<h2>申领印章</h2>
				<p>在线申领  高效便捷</p>
        <span class="btn" @click="link('/web/#/register/register')">立即申领 >></span>
			</div>
			<img class="icon" src="@/assets/images/system_pic.png" alt="申领电子公章" />
		</div>
		<div class="links">
			<div class="item" @click="link('/web/')">
				<div>
					<h2>印章使用</h2>
					<p>简单应用  安全管控</p>
					<img class="arrow-right" src="@/assets/images/arrow_right.png" />
				</div>
				<img class="icon" src="@/assets/images/system_pic1.png" alt="签章应用" />
			</div>
			<div class="item" @click="link('')">
				<div>
					<h2>签章验证</h2>
					<p>统一验证  互信互认</p>
					<img class="arrow-right" src="@/assets/images/arrow_right.png" />
				</div>
				<img class="icon" src="@/assets/images/system_pic2.png" alt="文档验证" />
			</div>
		</div>
	</div>
	<el-dialog title="" width="300px" :visible.sync="progressOpen">
		<div class="loading"><img src="../assets/images/loading.gif" /><p>加载中...</p></div>
	</el-dialog>
	<div class="body-content" id="fileselect">

		<h2 class="title">签章验证</h2>
		<div class="search-area">
			<div class="upload-div">
				<el-upload accept=".pdf,.ofd" :multiple="false" :show-file-list="false" :disabled="uploadDisabled"
					:on-success="uploadSuccess" :on-change="chooseFile"
					:file-list="fileList" :on-error="uploadError"
					:action="baseApi+'sealStateRelease/fileVerify/verify'" ref="upload"
					:auto-upload="false">
					<template v-if="!file">
						<div class="upload">
							<img src="@/assets/images/upload.png" />
							<label>上传PDF、OFD格式文档</label>
						</div>
					</template>
					<template v-else>
						<div class="upload">
							<span class="file-name">{{file}}</span>
						</div>
					</template>
				</el-upload>
			</div>
			<div class="btn" @click="search">{{btnText}}</div>
		</div>
		<div id="RESULT"></div>
		<!-- 未篡改0-未知 1-有效  已篡改-2  3-验证不了 1000-备案证明有效  1001-备案证明无效-->
		<div v-if="resultType <= 2" class="result">
			<div class="result-title">
				验证结果
			</div>
			<div class="table-bg">
				<div class="title"><span class="dot"></span>验证结果</div>
				<div class="table">
					<div class="row">
						<div class="col final-result">
							<!-- <label style="width: 96px;">签章有效性：</label>
							 -->
							<span v-if="resultType == 0" class="data"><span class="unknow">未检测到全国平台备案的电子印章，状态未知</span></span>
							<span v-if="resultType == 1" class="data success-color"><img class="icon" src="@/assets/images/yes.png" />自签章后，文档未被篡改<img class="icon-status"
									src="@/assets/images/success.png" /></span>
							<span v-if="resultType == 2" class="data"><img class="icon" src="@/assets/images/no.png" /><span class="unknow">自签章后，文档已被篡改</span><img
									class="icon-status" src="@/assets/images/fail.png" /></span>
						</div>
					</div>
				</div>
				<div class="title" v-if="sealDetailVOList.length"><span class="dot"></span>签署信息<span class="tips">（文档中共有 <span
							class="count">{{sealDetailVOList.length}}</span> 个签章）</span></div>
				<div class="table-box">
					<div class="table" v-for="item in sealDetailVOList" :key="item.creditCode">
						<template v-if="item.sealInfo.signType != 1">
							<div class="row">
								<div class="col seal-sign">
									<label>印章图像：</label>
									<span class="data pic">
										<img v-if="resultType != 1" class="watermark" :style="'width: ' + item.sealInfo.picWidth + 'px'" src="@/assets/images/watermark-non.png" alt="" />
										<img v-if="resultType == 2" :style="'width: ' + item.sealInfo.picWidth + 'px'" class="v-fail" src="@/assets/images/verify_seal_fail_f.png" />
										<img v-if="resultType == 0" :style="'width: ' + item.sealInfo.picWidth + 'px'" class="v-fail" src="@/assets/images/verify_seal_fail.png" />
										<img class="sign" :style="'width: ' + item.sealInfo.picWidth + 'px'" :src="'data:image/Jpeg;base64,'+item.sealInfo.sealPerPic" />
									</span>
									<!-- <p class="position" v-if="item.sealInfo.signType == 2"><span>骑缝章</span></p>
									<p class="position" v-else>
										<template v-if="item.sealInfo.posPage.indexOf(',') == -1">
											<span>签章位置：</span><span>位于第{{ item.sealInfo.posPage }}页</span>
										</template>
										<template v-else>
											<span>多页章</span>
										</template>
									</p> -->
								</div>
							</div>
						</template>
						<template v-else>
							<div class="row">
								<div class="col seal-sign">
									<label>印章图像：</label>
									<span class="data pic">
										<img v-if="resultType == 2" class="v-fail" :style="'width: ' + item.sealInfo.picWidth + 'px'" src="@/assets/images/verify_seal_fail_f.png" />
										<img v-if="resultType == 0" class="v-fail" :style="'width: ' + item.sealInfo.picWidth + 'px'" src="@/assets/images/verify_seal_fail.png" />
										<img class="sign" :style="'width: ' + item.sealInfo.picWidth + 'px'" :src="'data:image/Jpeg;base64,'+item.sealInfo.sealPerPic" />
									</span>
									<!-- <p class="position"><span>签章位置：</span><span>位于第{{ item.sealInfo.posPage }}页</span></p> -->
								</div>
							</div>
						</template>
						<div class="row">
							<div class="col">
								<label>签署主体：</label>
								<span v-if="item.haveTag != 0 && item.certInfo.Subject"
									class="data">{{item.certInfo.SubjectCN}}</span>
								<span v-else-if="item.certInfo.Subject" class="data">{{item.certInfo.SubjectCN}}</span>
								<span v-else class="data"><span class="unknow">未知</span></span>
							</div>
						</div>
						<!--
						<div class="row">
							<div class="col">
								<label>签署平台：</label>
								<span v-if="item.blockchain.signPlatform" v-text="item.blockchain.signPlatform" class="data"></span>
								<span v-else class="data"><span class="unknow">未知</span></span>
							</div>
						</div> -->
						<div class="row">
							<div class="col">
								<label>签章时间：</label>
								<span class="data">{{item.sealInfo.signTime}}</span>
							</div>
						</div>
						<div class="row" v-if="resultType == 1" >
							<div class="col">
								<label style="letter-spacing: 3px;">印章有效期</label> <!-- 为了对齐，冒号对齐 -->
								<span class="data" v-if="item.certInfo.NotBefore && item.certInfo.NotAfter"><span style="margin-left: 1px;">：</span>
									<img class="icon" src="@/assets/images/guomi_icon.png" />
									{{item.certInfo.NotBefore}}
									&nbsp;<span>至</span>&nbsp;
									{{item.certInfo.NotAfter}}
								</span>
								<span class="data" v-else>：</span>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<label>制作服务平台：</label>
								<span class="data">
									<template v-if="item.trustedSource && resultType == '1'">
										<img class="icon" src="@/assets/images/guohui.png" />{{item.trustedSource}}
									</template>
									<template v-else>
										<span style="color: #F16864;">未知</span>
									</template>
								</span>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<label>备案服务单位：</label>
								<span class="data">
									<template v-if="resultType == '1'">
										<img class="icon" src="@/assets/images/guohui.png" />公安部第三研究所
									</template>
									<template v-else>
										<span style="color: #F16864;">未知</span>
									</template>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else-if="resultType == 3" class="result">
			<div class="result-title">
				验证结果
			</div>
			<div class="table-bg">
				<div class="title"><span class="dot"></span>基本信息</div>
				<div class="table">
					<div class="row">
						<div class="col">
							<label style="width: 96px;">签章有效性：</label>
							<span class="data"><span class="unknow">{{ msg }}</span></span>
						</div>
					</div>
				</div>

			</div>
		</div>
		<!-- <div v-else-if="resultType == 3" class="result">
			<div class="result-title">
				验证结果
			</div>
			<div class="file-result">
				<img class="result-icon" src="@/assets/images/none.png" />
				<label class="result-tips">未检测到有效的签章信息</label>
			</div>
		</div> -->
		<div v-else-if="resultType == 4" class="result">
			<div class="result-title">
				验证结果
			</div>
			<div class="file-result">
				<img class="result-icon" src="@/assets/images/error.png" />
				<label class="result-tips">{{msg}}</label>
			</div>
		</div>

		<!-- -备案证明验证结果start  1000-备案证明有效  1001-备案证明无效-->
		<div v-if="resultType==1000" class="result">
			<div class="result-title">
				验证结果
			</div>
			<div class="table-bg">
				<div class="title"><span class="dot"></span>验证结果</div>
				<div class="table">
					<div class="row">
						<div class="col final-result">
							<span  class="data success-color"><img class="icon" src="@/assets/images/yes.png" />经验证，此备案证明有效</span>
						</div>
					</div>
				</div>
				<div class="title"><span class="dot"></span>备案信息</div>
				<template v-for="(item,index) of sealDetailVOList">
					<div class="table" :key="index">
						<div class="row">
							<div class="col seal-sign">
								<label>备案印文：</label>
								<span class="data pic bazm">
									<img class="sign" :style="'width: ' + item.sealInfo.picWidth + 'px'" :src="'data:image/Jpeg;base64,'+item.sealInfo.sealPerPic" />
								</span>
							</div>
						</div>
						<div class="row-flex-wrap">
							<div class="row">
								<div class="col">
									<label>印章名称：</label>
									<span class="data">{{item.certInfo.SubjectCN}}</span>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<label>印章有效期：</label>
									<span class="data" v-if="item.certInfo.NotBefore && item.certInfo.NotAfter">
										<img class="icon" src="@/assets/images/guomi_icon.png" />
										{{item.certInfo.NotBefore}}
										&nbsp;<span>至</span>&nbsp;
										{{item.certInfo.NotAfter}}
									</span>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<label>制作服务平台：</label>
									<span class="data"><img class="icon" src="@/assets/images/guohui.png" />{{item.trustedSource}}</span>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<label>备案服务单位：</label>
									<span class="data"><img class="icon" src="@/assets/images/guohui.png" />公安部第三研究所</span>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<label>备案编号：</label>
									<span class="data">{{item.certInfo.SubjectCN}}</span>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<label>备案日期：</label>
									<span class="data">{{item.certInfo.SubjectCN}}</span>
								</div>
							</div>
						</div>

					</div>

				</template>
			</div>
		</div>

		<div v-if="resultType==1001" class="result">
			<div class="result-title">
				验证结果
			</div>
			<div class="table-bg bazm-fail-wrap">
				<div class="title"><span class="dot"></span>验证结果</div>
				<div class="bazm-fail-box">
					<img class="bazm-fail-img" src="@/assets/images/bazm-fail.png" />
					<span class="bazm-fail-text">经验证，此备案证明无效</span>
				</div>
			</div>
		</div>
		<!-- -备案证明验证结果end -->
		<!--
		<el-dialog :visible.sync="open" :width="verifyAttr.width">
			<div class="verify-title" slot="title">请完成安全验证</div>
			<slide-verify ref="slideblock" @again="onAgain" :imgs="images" @fulfilled="onFulfilled" :w="verifyAttr.w" :h="verifyAttr.h"
				@success="onSuccess" @fail="onFail" @refresh="onRefresh" :accuracy="accuracy" :slider-text="text">
			</slide-verify>
		</el-dialog>

		<el-dialog :visible.sync="progressOpen" :width="verifyAttr.width">
			<div class="verify-title" slot="title">提示</div>
			<p>文档上传验证中，请稍后...</p>
			<el-progress :text-inside="true" :stroke-width="16" :percentage="percentage"></el-progress>
			<div style="width: 100%;height:80px;"></div>
		</el-dialog>
		-->
		<el-dialog
		  title="安全验证"
		  :visible.sync="open"
		  :close-on-click-modal="false"
		  width="430px"
		  class="validate-dialog"
		  >
		  <div class="validateCode">
			  <div class="info"><el-input class="input" v-model="inputVal" @keyup.native.enter="confirmCaptcha" placeholder="请您填写一下验证码答案" /></div>
			  <div class="img"><img :src="captcha.img" @click="getCaptcha" /><span @click="getCaptcha">看不清换一张</span></div>
		  </div>
		  <span slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="confirmCaptcha">确 定</el-button>
		  </span>
		</el-dialog>

		<div class="part">
			<h2 class="title">验证指南</h2>
			<div class="card">
				<div class="v-line"></div>
				<div class="item">
					<img class="icon" src="@/assets/images/01.png" />
					<p class="name">文档验证</p>
					<p class="desc">支持对电子签章的文档进行有效性验证</p>
				</div>
				<img class="arrow" src="@/assets/images/arrow.png" />
				<div class="item">
					<img class="icon" src="@/assets/images/02.png" />
					<p class="name">上传需要验证的文档</p>
					<p class="desc">上传需要待验证的文档</p>
				</div>
				<img class="arrow" src="@/assets/images/arrow.png" />
				<div class="item">
					<img class="icon" src="@/assets/images/03.png" />
					<p class="name">执行验证</p>
					<p class="desc">点击“立即验证”</p>
				</div>
				<img class="arrow" src="@/assets/images/arrow.png" />
				<div class="item">
					<img class="icon" src="@/assets/images/04.png" />
					<p class="name">验证文档有效性</p>
					<p class="desc">验证结果包括：签署主体、签署平台、签章图像等信息</p>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
	import img from '@/assets/images/verify.jpg'
	import { getAliOssStsTokenApi, getCaptchaApi, verifyCodeApi } from '@/api/send'
	import axios from "axios";
	import crypto from "crypto-js";
	import { provinceGeo } from "@/utils/province.js";
	export default {
		name: 'verify',
		data() {
			return {
				baseUrl: process.env.VUE_APP_BASE_API,
				captcha: {},
				images: [img],
				msg: '',
				open: false,
				file: '',
				progressOpen: false,
				// 0-有效性未知 1-有效 2-篡改失效 3-其他，即验不了 1000-备案证明有效  1001-备案证明无效
				resultType: 99,
				fileList: [],
				baseApi: process.env.VUE_APP_BASE_API,
				sealDetailVOList: null,
				allVerifyResult: null,
				percentage: 0,
				btnText: '立即验证',
				uploadDisabled: false,
				text: '向右拖动滑块填充拼图',
				// 精确度小，可允许的误差范围小；为1时，则表示滑块要与凹槽完全重叠，才能验证成功。默认值为5
				accuracy: 3,
				ossSignatureKey: '',
				sealTypes: {
					'1': '法定名称章',
					'2': '财务专用章',
					'3': '发票专用章',
					'4': '合同专用章',
					'5': '法定代表人名章',
					'99': '其他类型印章',
					'null': ''
				},
				verifyAttr:{
					width: '430px',
					w: 350,
					h: 198
				},
				inputVal: "",
				checkCode: "",
				result: ""
			}
		},
		mounted() {
			//this.getCaptcha();
		},
		filters: {
			substring(str) {
				if (!str) {
					return '';
				} else {
					let strAry = str.split(',');
					let fullStr = '';
					strAry.forEach(item => {
						if (item.trim().indexOf('CN=') == 0) {
							fullStr = item.trim();
						}
					});
					//console.log(fullStr);
					if (fullStr) {
						return fullStr.substring(3, fullStr.length)
					} else {
						return '';
					}
				}
			}
		},
		methods: {
			confirmCaptcha()
			{
				var numRe = new RegExp(/^(0|[1-9][0-9]*|-[1-9][0-9]*)$/);
				if(this.inputVal == '')
				{
					this.$msgbox({
						title: '提示',
						message: '验证码不能为空'
					})
					return false;
				}
				else if(!numRe.test(this.inputVal))
				{
					this.$msgbox({
						title: '提示',
						message: '验证码格式错误'
					})
					return false;
				}
				var data = {
					uuid : this.captcha.uuid || '',
					code : this.inputVal || ''
				}
				verifyCodeApi(data).then(res => {

					if(res.code == 0)
					{
						this.onSuccess();
					}else{
						this.$msgbox({
							title: '提示',
							message: '验证码错误'
						})
						this.inputVal = '';
						this.getCaptcha();
						return false;
					}
				}).catch(err => {
					console.log(err)//代码错误、请求失败捕获
				})
			},

			getCaptcha()
			{
				getCaptchaApi({}).then(res => {
					//console.log(res.data);
					this.captcha = res.data;

				})
			},
			async getAliOssStsToken(prefixPath)
			{
				var data = {
					uuid : this.captcha.uuid,
					code : this.inputVal
				}
				const ossStsToken = await getAliOssStsTokenApi(data)
				if (ossStsToken.code != '0') {
					this.$msgbox({
						title: '提示',
						message: ossStsToken.msg || ossStsToken.message
					})
					return new Promise((resolve, reject) => {
						reject(ossStsToken)
					})
				}
				function computeSignature(accessKeySecret, canonicalString) {
					return crypto.enc.Base64.stringify(crypto.HmacSHA1(canonicalString, accessKeySecret));
				}
				const date = new Date();
				date.setHours(date.getHours() + 1);
				const policyText = {
					expiration: date.toISOString(), // 设置policy过期时间。
					conditions: [
					// 限制上传大小。
						["content-length-range", 0, 1024 * 1024 * 1024],  //  1G
					],
				};
				const Base64 = require('js-base64')
				var formData = new FormData()
				this.ossSignatureKey = `${prefixPath}/${date.getFullYear()}${date.getMonth() >= 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)}${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}/${this.uuid()}.${this.file.split('.').pop()}`
				formData.append('key', this.ossSignatureKey)
				formData.append('policy', Base64.encode(JSON.stringify(policyText)))
				formData.append('signature', computeSignature(ossStsToken.data.accessKeySecret, Base64.encode(JSON.stringify(policyText))))
				formData.append('OSSAccessKeyId', ossStsToken.data.accessKeyId)
				formData.append('x-oss-security-token', ossStsToken.data.securityToken)
				formData.append('success_action_status', '200')
				formData.append('file', this.fileList[0].raw)
				//console.log(formData, 'ossSignature')
				return new Promise((resolve, reject) => {
					resolve(formData)
				})
			},
			ossFileVerifyFC() {
				axios({
					url: process.env.VUE_APP_OSSVERIFY_URL,
					method: 'POST',
					data: process.env.VUE_APP_OSSUPLOAD_URL === 'demo' ? {
						"filePath": this.fileList[0].name,
						"watermark":"",
						"isOutSealPic":"1",
						"token": "90f61f574f3fdd3315f9b72e37f0d56629d9e3ce-0bcb-4ee6-a9b8-6bb9d8d346a6"
					} : {
						token: '90f61f574f3fdd3315f9b72e37f0d566202008081729002',
   	              		filePath: this.ossSignatureKey
					},
					dataType: 'json'
				}).then(res => {

				  //console.log('res.data', res.data);
				  this.uploadSuccess(res.data);

				}).catch(err => {
				  console.log('err');
				})
			},
			uuid() {
				return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
					var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
					return v.toString(16)
				})
			},
			ossVerifyUpload(ossSignature){
				console.log('ossSignature', ossSignature);
				console.log('this.baseUploadUrl', this.baseApi);
				axios({
					url: process.env.VUE_APP_OSSUPLOAD_URL,
					method: 'POST',
					headers: {
						"Frontend-Tag":80015,
						'x-oss-storage-class': 'Standard',
						'x-oss-object-acl': 'private',
						'x-oss-tagging': 'Tag1=1&Tag2=2',
						'x-oss-forbid-overwrite': 'true'
						},
					data: ossSignature,
					responseType: "json"
				}).then(res => {
				  if (res.status == 200) {
					this.ossFileVerifyFC()
				  }
				}).catch(err => {
				  console.log('err');
				})
			},
			startUpdateComplete() {
			      this.progressOpen = true;
			      //this.percentage = Math.ceil(Math.random() * 100);
			      let timer = setInterval(() => {
			        if (this.percentage >= 90) {
			          clearInterval(timer);
			        } else {
			          this.percentage += Math.ceil(Math.random() * 10);
			        }
			      }, 300);
			    },
			onSuccess() {
				// console.log('验证通过');
				// that.open = false;
				if (process.env.VUE_APP_OSSUPLOAD_URL === 'demo') {
					this.progressOpen = true;
					setTimeout(() => {
						this.ossFileVerifyFC()
					}, 3000);
				} else {
					this.startUpdateComplete();
					this.getAliOssStsToken('verifyCenter').then(res => {
						this.ossVerifyUpload(res)
					})
				}
				// const params = new FormData()
				// params.append('file', this.fileList[0].raw)
				// params.append('uuid', this.captcha.uuid)
				// params.append('code', this.inputVal)
				// axios({
				// 	url: process.env.VUE_APP_BASE_API + 'sealStateRelease/fileVerify/verify',
				// 	method: 'POST',
				// 	data: params,
				// 	responseType: "json"
				// }).then(res => {
				//   this.uploadSuccess(res.data)
				// }).catch(err => {
				//   console.log('onSuccess-err');
				// })
			},
			onFail() {
				console.log('验证不通过');
			},
			onRefresh() {
				console.log('点击了刷新小图标');
			},
			onFulfilled() {
				console.log('刷新成功啦！');
			},
			search() {
				if (this.btnText == '立即验证') {
					if (!this.file) {
						this.$confirm('请选择文件', '提示',{
							showClose:false,
							showCancelButton: false
						});
						return;
					}
					this.inputVal = '';
					this.onSuccess();
					// this.open = true;
					// this.getCaptcha();
					// if (this.$refs.slideblock)
					// 	this.$refs.slideblock.reset();
				} else {
					this.uploadDisabled = false;
					this.btnText = '立即验证';
					this.fileList = [];
					this.sealDetailVOList = null;
					this.allVerifyResult = null;
					this.file = '';
					this.$refs.upload.clearFiles();
					this.resultType = 99;
				}
			},
			close() {
				this.open = false;
			},
			onAgain() {
				console.log('检测到非人为操作的哦！');
				// 刷新
			},
			chooseFile(file, fileList) {
				if (file.size > 21000000) {
					this.fileList = [];
					this.file = '';
					this.$msgbox({
						title: '提示',
						message: '文件大于20M，请下载签章客户端进行验证'
					})
					return;
				}
				if (fileList.length > 0) {
					this.fileList = [fileList[fileList.length - 1]];
				}
				console.log(file);
				this.file = file.name;
				console.log('fileList', fileList);
			},
			// 上传成功回调
			uploadSuccess(res) {
				const that = this;
				// const document = document;
				this.percentage = 100;
				const timeOut = setTimeout(() => {
					that.progressOpen = false;
					that.percentage = 0;
					that.btnText = '取消';
					that.uploadDisabled = true;
					document.getElementById("RESULT").scrollIntoView({
						behavior: 'smooth'
					});
				}, 500);

				if (res.code == '1020000' ||res.code == '1020008') {
					this.allVerifyResult = res.allVerifyResult;

					var result = res.data || [];

					var haveZero = false;
					let itemList = []
					result.forEach((value, index) => {
						if(value.haveTag == '0'){
							haveZero = true;
						}
						if ((index == result.length - 1) && value.haveTag == '2') { //  三所印章展示有效
							haveZero = false;
						}
						value.certInfo.SubjectCN = this.signatoryFilter(value)[0]
      					// value.trustedSource = `全国电子印章管理与服务平台${provinceGeo[this.signatoryFilter(value)[1]]}平台`
      					// value.trustedSource = `全国电子印章管理与服务平台${this.signatoryFilter(value)[1] == '320000' ? '江苏省' : '广东省'}平台`	//	需求230714
						value.trustedSource = value.haveTag == '2'?'全国电子印章管理与服务平台':(value.sealInfo.sealName.split('##')[1] || '')	//	需求230721
						if (value.certInfo.NotAfter) {
							value.certInfo.NotAfter = value.certInfo.NotAfter.slice(0, 10)
						}
						if (value.certInfo.NotBefore) {
							value.certInfo.NotBefore = value.certInfo.NotBefore.slice(0, 10)
						}
						//	signType：0=电子印章 1=个人签名 2 骑缝章
						value.sealInfo.picWidth = value.sealInfo.sealType == 5 || value.sealInfo.signType == '1' ? '80' : '142'	//	对法人章、个人私章显示大小的处理
						//对接新版验签接口逻辑
						if(value.sealInfo && value.sealInfo.pages && value.sealInfo.pages.length > 1){
							value.sealInfo.pages.forEach(item=>{
								const copyValue = JSON.parse(JSON.stringify(value))
								copyValue.sealInfo.pageItem = item
								itemList.push(copyValue)
							})
						} else {
							itemList.push(value)
						}
					})


					// 未篡改
					if(res.allVerifyResult == 0){
						// 有效性
						if(res.lastHaveTag == 0 && haveZero){
							this.resultType = 0;
						}else{
							this.resultType = 1;
						}
						// this.msg = '未检测到全国平台备案的电子印章，状态未知'
					}else{
						// 已篡改
						this.resultType = 2;
					}

					this.sealDetailVOList = itemList.filter(item => {return item.haveTag == 1 || item.haveTag == 2;});
					// 判断是不是备案证明 注释备案证明
					// if(this.sealDetailVOList.length==1 && this.sealDetailVOList[0].sealInfo.signName.includes('BA') ){
					// 	this.resultType=this.resultType==1 ? '1000' : '1001'
					// }

				} else {
					this.resultType = 3;
					this.msg = res.msg || res.message;
				}
				// console.log('this.sealDetailVOList',this.sealDetailVOList)

			},
			// 上传进度
			uploadProgress(event, file, fileList) {
				//console.log('event.percent', event.percent);
				if (event.percent < 99) {
					this.percentage = parseInt(event.percent);
				}
			},
			// 上传失败
			uploadError() {
				this.$confirm('上传失败，请重新验证', '错误提示');
				this.progressOpen = false;
				this.percentage = 0;
			},
			link(url) {
				if(url == '')
				{
					document.getElementById("fileselect").scrollIntoView({
						behavior: 'smooth'
					});
				}else{
					if (document.cookie && document.cookie.includes('SERVICEPLATFORMTOKEN') && url == '/web/') {
						url = '/web/page/userCenter/userCenter.html'
					}
					console.log(url, '跳转链接,')
					window.open(url, this.baseUrl + url)
				}

			},
			signatoryFilter(Message) {
				let signMain = Message.sealInfo.sealName.split('##')[0];
				let provinceCode = '';
				let arr = Message.certInfo.Subject.split(',');
				for (let i = 0; i < arr.length; i++) {
					if (arr[i].includes('CN=')) {
						let temp = arr[i].split(' ');
						if (Message.sealInfo.sealType == 5) {	//	法人类型章先取sealInfo
							if (!signMain) {	//	sealName截取到空值则从Subject中取
								signMain = temp.filter((item) => /[\u4E00-\u9FA5]/.test(item))[0]
							}
						} else {	//	其他类型章按原逻辑取有值的Subject内容
							if (temp.length > 1 && !temp[1].includes('null')) {
								signMain = temp.filter((item) => /[\u4E00-\u9FA5]/.test(item))[0]
							}
						}
						provinceCode = temp[0].replace(/\D/g, '').slice(0, 3) + '000';
						break;
					} else {}
				}
				return [signMain, provinceCode];
			},
		}
	}
</script>
<style>
.loading{
	text-align:center;
}
@media screen and (max-width:768px) {
	.el-message-box {
		width: 80%!important;;
	}
}
</style>
<style lang="scss" scoped>
	.service {
		display: flex;
		flex-direction: row;
		justify-content: center;
		padding: 90px 0;

		.item {
			padding-left: 48px;
			border-radius: 8px;
			display: flex;
			position: relative;

			.icon {
				position: absolute;
				right: 0;
				bottom: 0;
			}

			h2 {
				padding: 29px 0 19px 0;
				font-size: 30px;
				color: #333333;
				font-weight: 400;
			}

			p {
				color: #999;
				font-size: 18px;
			}
		}

		.apply {
			width: 735px;
			height: 350px;
			background: #E2F1FF;
			margin-right: 30px;

			h2 {
				padding-top: 59px;
			}

			.btn {
				display: block;
				width: 150px;
				height: 50px;
				background: rgba(10, 79, 211, 0.5);
				border-radius: 25px;
				font-size: 18px;
				color: #fff;
				height: 50px;
				line-height: 50px;
				text-align: center;
				margin-top: 125px;
				cursor: pointer;
				transition: background .5s;
			}
			.btn:hover{
				background:rgba(10, 79, 211, 1);
			}
		}

		.links {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 735px;

			.item {
				height: 160px;
				margin: 0 0 30px 0;
				background-color: #D7EBFF;
				cursor: pointer;

				&:last-child {
					margin-bottom: 0;
				}

				.arrow-right {
					display: block;
					margin: 25px 0 0 0;
					transition: margin-left .5s;
				}
			}
			.item:hover .arrow-right {
					margin-left: 30px;
				}
		}
	}
	.body-content {
		background: #F8F9FA;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		.title{
			margin-bottom: 62px;
		}
		a{ color: #333; text-decoration: none;}
		.search-area {
			width: 1280px;
			padding: 0 110px;
			height: 240px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: url('@/assets/images/bg.jpg');
			background-size: 100% 100%;

			.upload-div {
				width: 990px;
				height: 64px;
				box-sizing: border-box;
				margin-right: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #fff;
				border-radius: 8px;
				overflow: hidden;

				.upload {
					display: flex;
					width: 990px;
					height: 64px;
					align-items: center;
					justify-content: center;

					label {
						cursor: pointer;
						font-size: 16px;
						font-weight: 400;
						color: #0A4FD3;
						line-height: 16px;
						margin-left: 10px;
					}

					.file-name {
						cursor: pointer;
						font-size: 16px;
						font-weight: 400;
						color: #666;
						line-height: 16px;
					}

					img {
						width: 30px;
						height: 20px;
					}
				}
			}

			.btn {
				background-color: #0A4FD3;
				text-align: center;
				color: #fff;
				width: 240px;
				height: 64px;
				cursor: pointer;
				font-size: 16px;
				line-height: 64px;
				border-radius: 8px;
				transition: background 0.5s;
			}
			.btn:hover {
				background:rgba(10, 79, 211, .8);
			}
		}

		.result {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 70px;
			padding-top: 60px;

			.result-title {
				width: 809px;
				height: 18px;
				margin-top: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 24px;
				font-weight: 400;
				color: #1344A1;
				background-image: url('@/assets/images/line.png');
				background-size: 100% 100%;
			}

			.file-result {
				width: 1500px;
				padding: 60px;
				box-sizing: border-box;
				margin-top: 50px;
				display: flex;
				background-color: #fff;
				flex-direction: column;
				align-items: center;

				.result-icon {
					width: 164px;
					height: 153px;
					margin-top: 34px;
					margin-bottom: 48px;
				}

				.result-tips {
					height: 21px;
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					margin-bottom: 24px;
				}
			}

			.table-bg {
				width: 1500px;
				padding: 30px 0     60px 0;
				box-sizing: border-box;
				margin-top: 50px;
				// background-color: #fff;
				&.bazm-fail-wrap{
					background-color: transparent;

					.bazm-fail-box{
						margin-top: 22px;
						background-color: white;
						border: 1px solid ;
						height: 400px;
						background: #FFFFFF;
						border: 1px solid #CCCCCC;
						border-radius: 2px;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;

						.bazm-fail-img{
							margin-bottom: 24px;
							width: 148px;
							height: 165px;
						}
						.bazm-fail-text{
							color: #666666;
						}
					}
				}


				.title {
					position: relative;
					text-align: left;
					font-size: 18px;
					font-weight: 400;
					color: #333;
					line-height: 24px;
					margin: 30px 0 0 0;

					.tips {
						font-size: 14px;

						.count {
							color: #4081FF;
						}
					}

					.dot {
						display: block;
						position: absolute;
						left: -16px;
						top: 2px;
						width: 6px;
						height: 20px;
						background: #4081FF;
						border-radius: 0px 2px 2px 0px;
					}
				}

				.table-box {
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					width: 100%;

					.table {
						width: 735px;
					}
				}

				.table {
					margin-top: 20px;
					margin-bottom: 10px;
					position: relative;

					.row-flex-wrap{
						display: grid;
						grid-template-columns: 1fr 1fr;
						border-top: 1px solid #ccc;
						border-left: 1px solid #ccc;
						.row{
							border-radius: 0 !important;
							border-right: 1px solid #ccc;
							border-bottom: 1px solid #ccc;
							border-top: 0;
							border-left: 0;
						}
					}

					.row {
						display: flex;
						line-height: 46px;
						background-color: #fff;
						border: 1px solid #ccc;
						border-bottom: none;

						.seal-sign {
							height: 190px;
							position: relative;

							.status {
								position: absolute;
								width: 159px;
								height: 120px;
								right: -20px;
								top: -6px;
							}
							.position {
								margin: 0;
								color: #ccc;
								position: absolute;
								left: 0;
								right: 0;
								bottom: 0;
								text-align: center;
								line-height: 24px;
								font-size: 14px;
							}

							.data {
								position: absolute;
								left: 110px;
								top: 0;
								bottom: 0;
								&.bazm{
									left: 0;
									top: 0;
									bottom: 0;
									right: 0;
									display: flex;
									align-items: center;
									justify-content: center;
								}
								.v-fail {
									width: 113px;
									position: absolute;
									left: 0;
									right: 0;
									top: 0;
									bottom: 0;
									margin: auto;
									z-index: 100;
								}
								.watermark {
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
								}

							}
						}

						.col {
							padding: 0 10px 0 30px;
							border-right: 1px solid #ccc;
							flex: 1;
							display: flex;
							// align-items: center;
							color: #666;
							font-size: 16px;

							label {
								color: #333333;
								font-weight: 400;
							}

							.data {
								display: flex;
								align-items: center;
								flex: 1;
								&.success-color{
									color:#5FBF5B ;
								}

								.unknow {
									color: #F16864;
								}

								.sign {
									width: 142px;
									display: flex;
									position: relative;
								}
							}

							.pic {
								justify-content: center;
							}

							.icon {
								vertical-align: middle;
								width: 25px;
								height: 25px;
								margin-right: 10px;
							}
							.icon-status {
								width: 100px;
								height: 25px;
								margin-left: 10px;
							}
						}

						.col:last-child {
							border-right: none;
						}
					}

					.row:first-child {
						border-top-left-radius: 4px;
						border-top-right-radius: 4px;
					}

					.row:last-child {
						border-bottom-left-radius: 4px;
						border-bottom-right-radius: 4px;
						border-bottom: 1px solid #ccc;
					}
				}
			}
		}

		::v-deep(.el-dialog__body) {
			padding: 24px 40px 30px !important;
		}

		::v-deep(.el-dialog__header) {
			border-bottom: 1px solid #eee;
		}

		.verify-title {
			text-align: left;
			font-size: 16px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #666666;
		}

		.part {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			.title {
				margin-top: 102px;
				margin-bottom: 72px;
			}

			.card {
				width: 1500px;
				display: flex;
				position: relative;
				padding: 0 20px;
				box-sizing: border-box;
				height: 340px;
				justify-content: space-around;

				.item {
					position: relative;
					z-index: 2;
					width: 25%;
					text-align: center;

					.icon {
						width: 134px;
						height: 134px;
					}

					.name {
						margin: 17px 0;
						font-size: 18px;
						font-weight: 500;
						color: #333;
					}

					.desc {
						margin: 0;
						text-align: center;
						font-size: 14px;
						font-weight: 400;
						color: #999;
						line-height: 24px;
					}
				}

				.v-line {
					background: #E4E4E4;
					height: 2px;
					margin-top: 70px;
					width: 1100px;
					position: absolute;
					z-index: 1;
				}

				.arrow {
					position: inherit;
					z-index: 2;
					width: 27px;
					height: 31px;
					margin-top: 56px;
				}
			}
		}

		.validate-dialog{
			.info{
				margin: 20px 0 30px 0;
				.input{
					width: 100%;
					font-size: 16px;
				}
			}
			.img {
				height: 60px;
				line-height: 60px;
				img{
					height:60px;
				}
				span{
					color: #0B4FD4;
					font-size: 14px;
					vertical-align: top;
					cursor: pointer;
					margin-left: 17px;
				}
			}
			.dialog-footer .el-button{
				background: #0B4FD4;
				border: none;
				height: 36px;
				line-height: 50%;
			}
		}

		@media screen and (max-width:1499px) {
			.search-area {
				width: 1140px;
				padding: 0 30px;
			}
			.result {

				.table-bg {
					width: 1200px;
					padding: 0 0 50px 0px;

					.table-box {
						width: 100%;

						.table {
							width: 48.8%;
						}
					}
				}
			}

			.part {
				.card {
					width: 1200px;
					display: flex;
					position: relative;
					padding: 0 20px;
					box-sizing: border-box;
					height: 340px;
					justify-content: space-around;

					.v-line {
						width: 800px;
					}
				}
			}
		}
	}
@media screen and (max-width:720px) {
	.service {
		flex-direction: column;
		padding: 20px 15px 80px 15px;
		width: auto;

		.item {
			padding-left: 0;
			border-radius: 8px;
			display: flex;
			position: relative;

			.icon {
				width: 40%;
			}

			h2 {
				padding: 30px 0 10px 20px;
				font-size: 22px;
				color: #333333;
				font-weight: 500;
			}

			p {
				padding: 0 0 10px 20px;
				font-size: 16px;
			}
		}

		.apply {
			width: 100%;
			height: 160px;
			margin-right: 0;
			margin-bottom: 10px;

			.btn {
				display: block;
				width: 120px;
				border-radius: 20px;
				font-size: 16px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				margin-top: 5px;
				margin-left: 20px;
			}
		}

		.links {
			display: flex;
			flex-direction: row;
			justify-content: center;
			width: 100%;

			.item {
				height: 145px;
				width: 50%;
				margin: 0 0 0 0;

				&:last-child {
					margin: 0 0 0 10px;
				}

				.icon {
					width: 60%;
				}

				.arrow-right {
					margin: 15px 0 0 20px;
					transition: margin-left .5s;
				}
			}
		}
	}

	.body-content {
		background: none;
		width: 100%;

		.search-area {
			padding: 50px 5%;
			margin-bottom:80px;
			width:90%;
			height: auto;
			flex-direction:column;

			.upload-div {
				width: 100%;
				margin: 0 0 20px 0;
			}

			.btn {
				width: 100%;
				height: 50px;
				line-height: 50px;
				font-size: 16px;
				border-radius: 30px;
			}
		}

		.result {
			margin-bottom: 80px;
			padding:0 15px;
			width: 90%;
			.col.final-result{
				padding: 10px !important;
			}
			.result-title {
				width: 100%;
				margin-top: 0;
				background-position: center center;
				background-size: 200% 100%;
			}

			.file-result {
				width: 100%;
				padding: 0;
				margin-top: 50px;

				.result-icon {
					width: 164px;
					height: 153px;
					margin-top: 34px;
					margin-bottom: 48px;
				}

				.result-tips {
					height: 21px;
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					margin-bottom: 24px;
				}
			}

			.table-bg {
				width: 100%;
				padding: 0;
				box-sizing: border-box;
				margin-top: 30px;
				// background-color: #fff;

				.title {
					position: relative;
					text-align: left;
					font-size: 18px;
					font-weight: 400;
					color: #333;
					line-height: 24px;
					margin: 30px 0 0 15px;

					.tips {
						font-size: 14px;

						.count {
							color: #4081FF;
						}
					}

					.dot {
						display: block;
						position: absolute;
						left: -16px;
						top: 2px;
						width: 6px;
						height: 20px;
						background: #4081FF;
						border-radius: 0px 2px 2px 0px;
					}
				}

				.table-box {
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					width: 100%;

					.table {
						width: 100%;
					}
				}

				.table {
					margin-top: 20px;
					margin-bottom: 10px;
					position: relative;

					.row {
						display: flex;
						line-height: 46px;
						background-color: #fff;
						border: 1px solid #ccc;
						border-bottom: none;

						.seal-sign {
							height: 190px;
							position: relative;

							.status {
								position: absolute;
								width: 159px;
								height: 120px;
								right: -20px;
								top: -6px;
							}
							.position {
								margin: 0;
								color: #ccc;
								position: absolute;
								left: 0;
								right: 0;
								bottom: 0;
								text-align: center;
								line-height: 24px;
								font-size: 14px;
							}

							.data {
								position: absolute;
								left: 0;
								top: 0;
								right: 0;
								bottom: 0;
								display: flex;
								align-items: center;
								justify-content: center;
								.v-fail {
									width: 113px;
									position: absolute;
									left: 0;
									right: 0;
									top: 0;
									bottom: 0;
									margin: auto;
									z-index: 100;
								}
								.watermark {
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
								}

							}
						}

						.col {
							padding: 0 10px;
							font-size: 14px;

							label {
								color: #333333;
								font-weight: 500;
							}

							.data {
								line-height: 20px;
							}
						}
					}
				}
			}
		}

		.verify-title {
			text-align: left;
			font-size: 16px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #666666;
		}

		.part {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 50px;

			.title {
				margin-top: 0;
				margin-bottom: 50px;
			}

			.card {
				width: auto;
				display: flex;
				position: relative;
				padding: 0 15px;
				box-sizing: border-box;
				height: auto;
				justify-content: space-around;
				flex-wrap: wrap;

				.item {
					margin: 15px 0;
					width: 50%;
					text-align: center;

					.icon {
						width: 90px;
						height: 90px;
					}

					.name {
						margin: 5px 0 0 0;
						font-size: 17px;
						font-weight: 600;
						color: #333;
					}

					.desc {
						font-size: 14px;
						color: #999;
						line-height: 20px;
					}
				}

				.v-line {
					display:none;
				}

				.arrow {
					display:none;
				}
				.arrow:nth-child(1){

				}
			}
		}

		.validate-dialog{
			.info{
				margin: 20px 0 30px 0;
				.input{
					width: 100%;
					font-size: 16px;
				}
			}
			.img {
				height: 60px;
				line-height: 60px;
				img{
					height:60px;
				}
				span{
					color: #0B4FD4;
					font-size: 14px;
					vertical-align: top;
					cursor: pointer;
					margin-left: 17px;
				}
			}
			.dialog-footer .el-button{
				background: #0B4FD4;
				border: none;
				height: 36px;
				line-height: 50%;
			}
		}

	}

	// 备案证明
	.row-flex-wrap{
		grid-template-columns: 1fr !important;
	}
}
</style>

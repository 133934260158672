import request from '@/utils/request'

export function getApplyStatusApi(params) {  //  区块链实时申领信息
  return request({
    url: 'sealStateRelease/applyStatus',
    method: 'get',
	params
  })
}


export function getAliOssStsTokenApi(params) {  // 获取token
  return request({
    url: 'sealStateRelease/fileVerify/token', //  'sealStateRelease/fileVerify/token' + '?uuid=' + this.captcha.uuid + '&code=' + this.inputVal,
    method: 'get',
	  params
  })
}


export function getCaptchaApi() {  //  获取验证码
  return request({
    url: 'sealStateRelease/fileVerify/captcha',
    method: 'get'
  })
}

export function verifyCodeApi(params) {  //  验证验证码是否正确
  return request({
    url: 'sealStateRelease/fileVerify/verifyCode',
    method: 'get',
	params
  })
}

export default { getApplyStatusApi, getAliOssStsTokenApi, getCaptchaApi, verifyCodeApi }
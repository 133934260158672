import JSEncrypt from "encryptlong"
import CryptoJS from "crypto-js"
import { sm2,sm4 } from 'sm-crypto'
import { Base64 } from 'js-base64'
// 密钥对生成 http://web.chacuo.net/netrsakeypair

// 请求加密开关（必须与后端保持一致）
const REQUEST_ENABLE = window.REQUEST_ENABLE
// 响应加密开关（必须与后端保持一致）
const RESPONSE_ENABLE = window.RESPONSE_ENABLE
// 加密字段 默认data
const REQUEST_FIELD = 'data'
//解密字段 默认data
const RESPONSE_FIELD = 'data'
//密钥对标识:目前可选【pub:ras算法 client:sm2算法】
const KEY_SIGNS = 'pub'
// 服务端提供的公钥(RSA)
const serverPublicKeyRSA = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCcF2Fs+WnlG1w8cy14WdwUYAwSaEkwFH26mUxJWFM2/i7hBpmcv1QCvMVDOgCaxpDtustqVyBTW28+eWMjacdGAE9n181vWIxBLt2dGptxLXPPd7rpEDoOJYidG/CdcZSUnuUBfS/NivaQKBhShQj530PPKRe/8eC+FFjLruT8FQIDAQAB'
// 自己的私钥(RSA)
const myPrivateKeyRSA = 'MIICdQIBADANBgkqhkiG9w0BAQEFAASCAl8wggJbAgEAAoGBAIDY/M46vPjxIB+H5l8usYYTFYoYuFX+zj8tu+mGWofj/8JyDDmKmvXSL/N8NhMn6Hilb6j9YdEjs4On+MHa51n83NoFAafn38vhHjcw+LRUamlyISAOU4qVvStxuNl0vX54W1kTMewfxpnZGy0Eiy+rOyARNLGRfqmO/7usXuBhAgMBAAECgYAEnL/f+gZNuwP1XP4IJjnAnOpGeUAQBAEKDe8OGvsepjC/mymI3cdSSWXiB25AHUubFgD7QabAv9BrRBYJinaBmwjiZ/TgNaVhYWU4bSxDPHVrSaVMGOtFX8X1LkqvosRw7wNuSt6dQAQx1ePgRN3L/EvkBFS6WQY1Sw65JrJHSQJBAM7GPgxt1UQQfG9Ae0fAF189sHBxeViedbcLsqCvxyiNFHWuaAtMaB+PrXGdQ5mt4SnuuWVre9R7q8Bu10/De7sCQQCfhYnD1z31ek0tfYIIkrQDSf66eY4GTulTTvCa7O0JnclxSQ/FO7V5LqV3PKO3dfv/rNSkSM7499OMN9Nm7TyTAkBnIHxl2omZLtfA7NoPiQCfIbdT/WRWDIINTypB9ilQUjy4DMobiHoN86y/k33Zsd0w+r1AlgDmkt+wVBfS0l5tAkAlxjp0PKD4YyipcAH9w7eK29fNEoG2LrODvbquBRKwZ0PPZqysWNJFSu11yc8+31p4FdYYvQD95bmbh5Cw29avAkBvAdZLerVKJw+oX5POlrtH6BG6HqFxZrT834h/zjTooKcFmf9WkXSydDer4v/pIdhhW+xMGHAf7z81ocaIjMiY'
// 服务端提供的公钥(SM2)
const serverPublicKeySM2 = 'MIIBMzCB7AYHKoZIzj0CATCB4AIBATAsBgcqhkjOPQEBAiEA/////v////////////////////8AAAAA//////////8wRAQg/////v////////////////////8AAAAA//////////wEICjp+p6dn140TVqeS89lCafzl4n1FauPkt28vUFNlA6TBEEEMsSuLB8ZgRlfmQRGajnJlI/jC7/yZgvhcVpFiTNMdMe8Nzai9PZ3nFm9zuNraSFT0KmHfMYqR0AC3zLlITnwoAIhAP////7///////////////9yA99rIcYFK1O79Ak51UEjAgEBA0IABEfhEQgNcBUTiK1f97E4QIbTtgoyzKgdDo4SpgBKGVF31mJGEntCkdGQQnDEhuMF8wTOSnR3BVWj2WtSOJl9TBg='
// 自己的私钥(SM2)
const myPrivateKeySM2 = 'AJ8yBPw2kz/ypEndvkE/CW2Nrvhd5ZZNZyR8Tyt0d7s1'
//请求数据加密(提供对外调用)
export function requestEncrypt(config) {
  config.headers['key-sign'] = KEY_SIGNS
  //前端过滤标识,请求中含有unencrypted=true的不做加密处理
  //如前端直接访问oss/云函数等
  if(!config.unencrypted && REQUEST_ENABLE){
    let encryptUrl = config.url.split('?')[0]
    let queryParams = config.url.split('?')[1]
    //post或get传输参数可能为json,注意命名一定要用params
    if(queryParams == undefined && config.params){
      queryParams = object2QueryStr(config.params)
      config.params = ''
    }
    if (/get/ig.test(config.method)) {
      if (queryParams) {
        encryptUrl += '?data=' + encodeURIComponent(dataEncrypt(decodeURIComponent(queryParams)))
      }
    } else {
      let postEncrypt = config.data || {}
      let postData = typeof config.data == 'string' ? JSON.parse(postData) : config.data
      config.headers['content-type'] = 'application/json'
      if (queryParams) {
        postData = Object.assign({}, postData, queryStr2object(queryParams))
      }
      postEncrypt = postData ? JSON.stringify({
        data: dataEncrypt(postData)
      }) : {}
      config.data = postEncrypt
    }
	config.url = encryptUrl
  }
}
//返回数据解密(提供对外调用)
export function responseDeEncrypt(type,res) {
  let unencrypted
  let response
  if(type == 'error'){
    unencrypted = res.response.unencrypted
    response = res.response.data
  }else{
    unencrypted = res.unencrypted
    response = res.data
  }
  //前端过滤标识,请求中含有unencrypted=true的不做加密处理
  //如前端直接访问oss/云函数等
  if(unencrypted || !RESPONSE_ENABLE){
    return response ? response : {}
  }
  if(KEY_SIGNS === 'client'){
    return decryptSM2(response.data)
  }
  return decryptAES(response.data)
}

//数据加密
function dataEncrypt(data) {
  data = typeof data == 'object' ? JSON.stringify(data) : data || ''
  if(KEY_SIGNS === 'client'){
    return encryptSM2(data)
  }
  var aesKey = random16()
  let rsaEncryptrRes = encryptRSA(aesKey)
  return rsaEncryptrRes + '_' + encryptAES(aesKey,data) + '_' + encryptAES(aesKey,new Date().getTime())
}
// AES算法
function encryptAES(aesKey,data) {
  var encrypted = CryptoJS.AES.encrypt(String(data), CryptoJS.enc.Utf8.parse(aesKey), {
      mode:CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.toString()
}
function decryptAES(data) {
  if(data==undefined || data==null || data==''){
    return {}
  }
  var dataProtocol = data.split("_")
  if (dataProtocol.length != 3){
    console.log('待解密数据分段异常(后端返回数据异常),分段数='+dataProtocol.length)
    return {};
  }
  var aesKey = decryptRSA(dataProtocol[0]) //解密后的aesKey
  var enBody = dataProtocol[1] //带解密data
  let restoreBase64 = enBody.replace(/[\r\n]/g, '')
  let decrypt = CryptoJS.AES.decrypt(restoreBase64, CryptoJS.enc.Utf8.parse(aesKey), {
     mode: CryptoJS.mode.ECB,
     padding: CryptoJS.pad.Pkcs7
   })
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}

// RSA算法（内部方法）
function encryptRSA(aesKey) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(serverPublicKeyRSA)
  return encryptor.encryptLong(aesKey)
}
function decryptRSA(aesKey) {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(myPrivateKeyRSA)
  return encryptor.decryptLong(aesKey)
}

// SM2加密
function encryptSM2(data) {
  if(data==undefined || data==null || data==''){
    return {}
  }
  let sm2Util = new SM2Util(myPrivateKeySM2, serverPublicKeySM2)
  return sm2Util.encrypt(data)
}
// SM2解密
function decryptSM2(data) {
   if(data==undefined || data==null || data==''){
     return {}
   }
   let sm2Util = new SM2Util(myPrivateKeySM2, serverPublicKeySM2)
   return sm2Util.decrypt(data)
}
//把字符串拼接转对象
function queryStr2object(str) {
  if (!str.replace(/\s/g, '')) return {}
  let queryArr = str.split('&')
  let object = {}
  queryArr.map(item => {
    let pair = item.split('=')
    object[pair[0]] = pair[1]
  })
  return object
}
//把对象转为字符串拼接,如GET请求传Json参数
function  object2QueryStr(obj) {
    var str = [];
    for (var p in obj) {
        str.push(p + "=" +obj[p])
    }
    return str.join("&")
}
// 获取16位随机数算法
function random16() {
  return 'xxxx4xxxyxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16)
  })
}
function hexToArray(hexStr) {
    const words = []
    let hexStrLength = hexStr.length
    if (hexStrLength % 2 !== 0) {
        hexStr = leftPad(hexStr, hexStrLength + 1)
    }
    hexStrLength = hexStr.length
    for (let i = 0; i < hexStrLength; i += 2) {
        words.push(parseInt(hexStr.substr(i, 2), 16))
    }
    return words
}
// SM4加密解密key需要转为32位16进制数
function arrayToHex(byteArray) {
  return Array.from(byteArray, function(byte) {
    return ('0' + (byte & 0xFF).toString(16)).slice(-2);
  }).join('')
}
const stringToHex = (str) => {
    let hex = '';
    for (let i = 0; i < str.length; i++) {
      const charCode = str.charCodeAt(i);
      const hexValue = charCode.toString(16);
      hex += hexValue.padStart(2, '0');
    }
    return hex;
};

class SM2Util{
    constructor(pricFromBC, pubJavaFromBC) {
        this.myPrivateKey = arrayToHex(Base64.toUint8Array(pricFromBC));
        this.serverPublicKey = '04' + arrayToHex(Base64.toUint8Array(pubJavaFromBC).slice(-64));
    }
    encrypt(text) {
        let sm4Key = random16()
        let timestamp = String(new Date().getTime())
        let sm4KeySec = Base64.fromUint8Array(hexToArray('04' + sm2.doEncrypt(stringToHex(sm4Key), this.serverPublicKey, 0)));
        let encPlaintext = sm4.encrypt(Base64.encode(text), stringToHex(sm4Key));
        let encTimestamp = sm4.encrypt(timestamp, stringToHex(sm4Key));
        let toSignCont = sm4KeySec + '_' + encPlaintext + '_' + encTimestamp;
        let signData = sm2.doSignature(toSignCont, this.myPrivateKey,  {
            hash: true,
            userId: '1234567812345678',
            der: true
        });
        let res = toSignCont+'_' + Base64.fromUint8Array(hexToArray(signData));
        // console.log(res)
        return res;
    }

    decrypt(decryptData) {
        var dataProtocol = decryptData.split("_")
        if (dataProtocol.length != 4){
         console.log('待解密数据分段异常(后端返回数据异常),分段数='+dataProtocol.length)
         return {};
        }
        let encSm4Key = dataProtocol[0]
        let encData = dataProtocol[1]
        let encTimeStamp = dataProtocol[2]
        let signValue = dataProtocol[3]
        let toVerifyStr = encSm4Key+'_'+encData+'_'+encTimeStamp;
        //web网页端不开启验签(只关注加解密),来提升体验速度
        // let signHex = arrayToHex(Base64.toUint8Array(signValue));
        // let verifyRes = sm2.doVerifySignature(toVerifyStr, signHex, this.serverPublicKey, {
        //     hash: true,
        //     userId: '1234567812345678',
        //     der: true
        // });
        // if(!verifyRes){
        //     console.log("验签失败");
        //     return {};
        // }
        let hexSm4Key = arrayToHex(Base64.toUint8Array(encSm4Key).slice(1));// 去掉第一个字符0x04
        let sm4Key = sm2.doDecrypt(hexSm4Key, this.myPrivateKey, 0);
        // console.log('sm4Key:', sm4Key);
        // let timestamp = sm4.decrypt(encTimeStamp, sm4Key);
        let text = Base64.decode(sm4.decrypt(encData, sm4Key));
        // console.log('text:' + text);
        return text;
    }
}

<template>
  <div class="main">
    <h2 class="title">常见问题</h2>
    <div class="search">
      <el-input placeholder="请输入问题的关键词搜索" v-model="searchValue" clearable @clear="reset">
      </el-input>
      <el-button @click="search">问题检索</el-button>
    </div>
    <el-tabs class="w" v-model="activeName" @tab-click="changeTab" stretch>
      <el-tab-pane :label="item.name" :name="item.key" v-for="item in tabs" :key="item.key">
        <el-collapse v-model="collapseActiveName" accordion v-if="currentContent.length > 0">
          <el-collapse-item :name="item.id" v-for="(item, index) in currentContent" :key="index">
            <template slot="title">
              <div class="collapse-title-ico"></div>
              <div class="collapse-title" v-html="item.title"></div>
            </template>
            <div v-html="item.test"></div>
          </el-collapse-item>
        </el-collapse>
        <el-empty v-else :image="require('../assets/images/empty.png')" description="换个关键词试试吧~"
          :image-size="300"></el-empty>
      </el-tab-pane>
    </el-tabs>
    <div class="w help">
      <img src="../assets/images/help.png" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      searchValue: "",
      activeName: "overview",
      collapseActiveName: "overview",
      currentContent: [],
      tabs: [
        {
          key: "overview",
          name: "概述",
        },
        {
          key: "applyFor",
          name: "申领",
        },
        {
          key: "filings",
          name: "备案",
        },
        {
          key: "use",
          name: "使用",
        },
        {
          key: "manage",
          name: "管理",
        },
        {
          key: "verification",
          name: "核验",
        },
      ],
      contents: {
        //概述
        overview: [{
          id: '1',
          title: '什么是电子印章，与实物印章有什么区别',
          test: `电子印章是一种由电子印章制作者数字签名的安全数据，包括电子印章所有者和图形化内容的数据。<br/>
									<span class="content-weight">相同点</span><br/>
									电子印章和实物印章具有相同的视觉效果，具有同等法律效力。<br/>
									<span class="content-weight">不同点</span><br/>
									防伪技术不同：实物印章具有防伪技术，一般是用在章面和印油、印泥上；电子印章基于的数字签名技术，安全性更高。<br/>
									适用范围不同：实物印章用于纸质文书；电子印章用于电子文书。<br/>
									实用性不同：实物印章只能本地盖章，受地域制约；电子印章方便异地盖章，成本低、效率高。`
        },
        {
          id: '3',
          title: '电子印章使用是否安全',
          test: `
							安全，主要从三个方面进行保障。<br/>
							<span class="content-weight">政策保障安全</span><br/>
							国家发布大量政策文件促进电子印章应用，系列标准规范电子印章应用。<br/>
								<span class="content-weight">技术保障安全</span><br/>
							电子印章采用商用密码技术保障真实性、完整性和不可否认性。<br/>
								<span class="content-weight">意识保障安全</span><br/>
							在数字化、移动化的发展趋势中，自我安全保障及自我防范意识已经得到增强。<br/>
							`
        }, {
          id: '4',
          title: '电子印章使用是否合规',
          test: `
							合规，主要从三个方面进行保障。<br/>
								<span class="content-weight">技术合规</span><br/>
							采用数字证书、时间戳等商密技术，保证签署身份可识别，签署时间记录精确，
							符合《 GB∕T 38540-2020 信息安全技术 安全电子签章密码技术规范》等标准要求。<br/>
								<span class="content-weight">管理合规</span><br/>
							符合《中华人民共和国电子签名法》《国家行政机关和企业事业单位社会团体印章管理的规定》《中华人民共和国公安部令第161号》要求的印章归公安机关管理的规定。<br/>
								<span class="content-weight">使用合规</span><br/>
							平台在公安部第三研究所相关部门指导下建成，且由公安机关颁发唯一的具备司法效力的备案证明，从源头上保证电子印章的安全可靠，具备合规性。
							`
        }, {
          id: '5',
          title: '电子印章与实物印章扫描图片有什么区别',
          test: `电子印章，是以密码技术为核心，将数字证书、签名密钥与印章图像有效绑定，用于实现各类电子文档完整性、真实性和不可抵赖性的图形化电子签名。<br/>
										仅将实物印章扫描的图片附在电子文档中，没有应用密码技术，无法进行签署文档内容保护，且无法进行有效查验，不具有司法效力。`
        }, {
          id: '6',
          title: '平台提供哪些服务内容',
          test: `平台提供全国范围内电子印章制发、备案、签章、查询、核验等公共服务。`
        }, {
          id: '7',
          title: '平台提供哪些应用产品',
          test: `
							平台提供多种形式用户应用产品，包含：<br/>
							<span class="content-weight">小程序</span><br/>
							<span class="content-weight">手机APP</span><br/>
							<span class="content-weight">服务门户</span><br/>
							<span class="content-weight">电脑客户端</span><br/>
							<span class="content-weight">标准API集成服务</span><br/>
							`
        }, {
          id: '8',
          title: '平台是否具备跨地区核验能力',
          test: `平台已实现电子印章全国互联互通、互信互认，具备跨地区、跨部门的可信核验能力。`
        }, {
          id: '9',
          title: '电子印章适用于哪些应用领域、应用场景',
          test: `
							电子印章服务于G端（政府）、B端（企事业）、C端（个人）。<br/>
							广泛应用于电子政务、医疗、教育、金融、交通等领域。<br/>
							常见的应用场景包括电子公文、电子证照、电子票据、电子合同等。<br/>
							`
        }, {
          id: '10',
          title: '使用电子印章有什么好处',
          test: `
							<span class="content-weight">即时签件 ，省时省力。</span><br/>
							只要有手机和电脑 ，就可实现远程异地便捷签署电子文件 ，无需跑腿 ，更无需打印、 邮寄。极大节省企业签章成本，提高企业工作效率。<br/>
							<span class="content-weight">格式通用 ，一键签章。</span><br/>
							平台支持多种文件格式的签章，并支持单页、多页、骑缝签章等方式，签得越多省得越多，即便几百页需要盖章的材料，也能一键完成。<br/>
							<span class="content-weight">广泛应用，互通互认。</span><br/>
							平台具备跨地区、跨部门的应用能力，支持在电子公文、电子证照、电子票据、电子合同等多种场景下的灵活应用。实现全国各地电子印章的互通互认、互签互验。<br/>
							<span class="content-weight">多重加密 ，安全可信。</span><br/>
							平台通过商密技术实现签署文件传输与存储安全加密，保障签署文件内容不可篡改，具有传统实物印章不可比拟的安全性和可追溯性。<br/>
							<span class="content-weight">技术保障 ，合法合规。</span><br/>
							 平台采用数字证书、人脸识别、 时间戳等技术，保证签署身份可识别，签署时间记录精确，符合《电子签名法》《 GB∕T 38540-2020 信息安全技术安全电子签章密码技术规范》等文件要求。<br/>
							`
        },
        ],
        //申领
        applyFor: [{
          id: '1',
          title: '电子印章在哪些渠道进行申领',
          test: `通过小程序、手机APP、省平台门户等渠道进行线上提交资料即可完成申领。`
        },
        {
          id: '3',
          title: '申领时需要提交哪些资料',
          test: `
											<span class="content-weight">针对企业单位</span><br/>
											提交企业营业执照、法人身份证件正反面、法人实名手机号码，并完成法人人脸认证。<br/>
											<span class="content-weight">针对事业单位</span><br/>
											事业单位申领电子印章，支持法定代表人、单位负责人两种办理人身份办理。<br/>
											法定代表人办理，提交单位营业执照/组织机构代码证/成立文件、法人身份证件正反面、法人实名手机号码，并完成法人人脸认证。<br/>
											单位负责人办理，提交单位营业执照/组织机构代码证/成立文件、法人身份证件正反面、实名手机号码、负责人身份证正反面、实名手机号码，并完成负责人人脸认证。<br/>
											<span class="content-weight">针对个人</span><br/>
											提交个人有效身份证正反面、实名手机号码，并完成个人人脸认证。`
        }, {
          id: '4',
          title: '电子印章的种类有哪些，如何选择',
          test: `
							电子印章的种类有：单位电子公章、个人电子私章。<br/>
							单位电子公章包含法定名称章、财务专用章、发票专用章、合同专用章、法定代表人名章五类公章以及部门专用章（例如：办公室专用章、董事会专用章、党支部公章等）。<br/>
							申领者可根据实际需求情况进行申领。
							`
        }, {
          id: '5',
          title: '平台支持申领哪些印章类型',
          test: `
							<span class="content-weight">单位电子公章</span><br/>
							法定名称章、财务专用章、发票专用章、合同专用章、法定代表人名章，仅限申领各一枚；
							同时，支持申领部门专用章等其他类型电子印章。<br/>
							<span class="content-weight">个人电子私章</span><br/>
							支持在线生成、在线手写、上传手写图片三种方式，仅限申领一枚。
							`
        }, {
          id: '6',
          title: '电子印章申领支持哪些主体 ',
          test: `支持企业、机关事业单位、社会团体、民办非企业及个人申领。`
        }, {
          id: '7',
          title: '新办企业是否可以申领',
          test: `可以。`
        }, {
          id: '8',
          title: '多家企业同一法人是否可以申领',
          test: `可以，目前同一法人支持添加多个单位，没有数量限制。`
        },
        ],
        // 备案
        filings: [{
          id: '1',
          title: '为什么要提供实名认证的手机号码',
          test: `
							提供实名认证的手机号码是为了确保电子印章的使用具有真实性和可追溯性，符合《电子签名法》的关于确保签署人身份的要求。<br/>
								<span class="content-weight">身份验证</span><br/>
							提供实名认证的手机号码可以用于验证用户的身份。在使用电子印章进行签章操作时，需要确保使用者是合法的签章人。通过实名认证的手机号码可以与用户的身份信息进行匹配，增加了身份验证的可靠性。<br/>
								<span class="content-weight">安全性</span><br/>
							实名认证的手机号码可以作为一个额外的安全层，防止未经授权的人员滥用电子印章。通过验证手机号码 ，可以限制电子印章的使用仅限于合法的用户，减少潜在的风险和滥用可能性。<br/>
								<span class="content-weight">可追溯性</span><br/>
							提供实名认证的手机号码可以用于追踪和审计电子印章的使用记录。如果发生争议或纠纷，可以通过手机号码追溯签章操作的相关信息，以确定签章人的身份和行为。
							`
        }, {
          id: '2',
          title: '办理电子印章后，实物印章是否会失效',
          test: `不失效，电子印章主要应用于电子文档的签署，更加便捷高效。`
        }],
        // 使用
        use: [{
          id: '1',
          title: '平台支持哪些签章模式',
          test: `支持单页、多页、骑缝等签章模式，用户根据实际需求进行用印。`
        },
        {
          id: '2',
          title: '怎样进行文件签署',
          test: `文件的签署可通过小程序、手机APP、电脑客户端、服务门户等渠道进行。点击“签文件”，选择待签署文件，选择电子印章并移动到合适位置，确认签署位置无误后，输入独立的签署密码，文件签署完成。
							`
        },
        {
          id: '3',
          title: '签署完成后如何进行分享转发',
          test: `
							<span class="content-weight">通过小程序签署的文件</span><br/>
							可直接分享给好友，也可下载到本地进行分享转发。<br/>
							<span class="content-weight">通过手机APP签署的文件</span><br/>
							可直接通过选择在线分享，也可下载到本地进行分享转发。<br/>
							<span class="content-weight">通过电脑客户端、统一门户签署的文件</span><br/>
							需要下载到本地进行分享转发。<br/>
							注：签章完成的电子文件在传递流转中，应保证电子文件的完整性，如果使用第三方阅读器进行编辑、保存等操作，将导致电子文件验签失败。
							`
        }, {
          id: '4',
          title: '电子印章有有效期吗',
          test: `
							电子印章按年续期，自申领之日起1年内有效。<br/>
							到期后电子印章自动失效，无法继续使用，需提前在线续期。<br/>
							`
        }, {
          id: '5',
          title: '加盖骑缝章需要注意的问题',
          test: `
							骑缝章仅支持两页以上的文档。<br/>
							文档内至少要加盖一枚印章。<br/>
							不能单独只盖骑缝章。<br/>
							`
        }, {
          id: '7',
          title: '分享签署时，对方是否需要注册账号',
          test: `需要。<br/>
							分享签署时，对方如果已经注册，直接登录即可进行签署；如果没有注册，系统将引导进入注册申领流程。
							`
        }, {
          id: '8',
          title: '平台是否支持应用系统API对接',
          test: `可以。<br/>
								平台针对中小企业等轻量级的用章场景提供电子印章SDK，实现与应用系统的无缝对接。<br/>
								提供JAVA、C语言等环境下的SDK包，在不改变现有操作流程的情况下，支撑各场景的电子印章应用。
							`
        }, {
          id: '9',
          title: '针对集团、连锁企业等大规模用章场景，有没有整体解决方案',
          test: `有的。<br/>
							平台针对大规模用章场景提供私有化部署的方式，并与平台对接；在本地实现企业用章自管理，平台提供电子印章的制发、验证能力；提供SDK方便企业应用对接。同时，小程序、电脑客户端、服务门户也可以为企业电子印章提供用章、验章等能力。
							`
        }, {
          id: '10',
          title: '导入WORD文档盖章时文档从一页变成两页怎么处理',
          test: `如出现上述问题，建议将待签章的文件转化为PDF等版式文件再发起签章。`
        }, {
          id: '11',
          title: '签章后，文件上的印章图像变小',
          test: `
							产生此类问题的原因是原始文件不是标准A4大小，需要先将文件纸张类型设定为A4纸张，再进行签章操作。
							`
        },
        {
          id: '15',
          title: '电子印章如何进行续费',
          test: `到期后将不能正常使用，需登录小程序、客户端、统一门户进行续费。`
        }, {
          id: '16',
          title: '电子印章续费后，怎样才能正常使用电子印章',
          test: `
							<span class="content-weight">小程序、服务门户更新</span><br/>
							在完成电子印章续费流程后，小程序、服务门户自动对电子印章使用进行更新。<br/>	
							<span class="content-weight">电脑客户端更新</span><br/>
							在完成电子印章续费流程后，需要进行电子印章更新操作，用户将电子印章插入电脑（确认电脑上已安装USBKey驱动环境）登录电子印章签章客户端，点击进行在线更新。<br/>
							`
        }, {
          id: '19',
          title: '使用过程中遇到问题怎么办',
          test: `可拨打咨询电话4001159966或“联系客服”在线咨询。`
        }
        ],
        // 管理
        manage: [{
          id: '1',
          title: '如何控制电子印章的使用权限',
          test: `根据自身的印章管理办法确定电子印章的使用权限，电子印章的登录及使用密码均需要妥善保存，设置复杂度高的密码，并定期修改使用密码，确保电子印章不被盗用或冒用。`
        }, {
          id: '2',
          title: '如何更换电子印章',
          test: `因单位或者机构变更、名称变更、法定代表人或经营者变更、 电子印章损坏等需要更换电子印章的，单位负责人应当申请注销原印章并重新制作。`
        }, {
          id: '3',
          title: '电子印章丢失或损坏后怎么处理',
          test: `电子印章丢失或损坏后，需要办理印章丢失/损坏补办业务。<br/>
						
						办理印章丢失/损坏补办业务后，原有印章将会自动吊销，将不能再继续使用。`
        }, {
          id: '4',
          title: '电子印章锁定后怎么办',
          test: `
						电子印章在忘记操作密码或者是多次输入错误密码锁定后，需要进行解锁操作。<br/>
						在线提交解锁申请，上传鉴证资料（营业执照、法定代表人有效身份证明等）。<br/>
						后台审核资料后发送授权邮件，用户通过授权邮件中的链接重置密码。
						`
        }, {
          id: '5',
          title: '电子印章怎样注销',
          test: `电子印章的注销可以在统一门户、小程序、客户端上操作。找到印章注销入口，按流程操作完成，密码验证正确后，后台自动注销电子印章。<br/>
						注：注销后的电子印章将无法使用。`
        }, {
          id: '6',
          title: '注销电子印章后可再次申领吗',
          test: `可以的，按照电子印章申领流程申领即可。`
        },],
        // 核验
        verification: [{
          id: '1',
          title: '签署完成后的电子文件如何验证',
          test: `可通过小程序、客户端、各省服务门户进行验证。`
        }, {
          id: '2',
          title: '签署完成后的电子文件验证无效',
          test: `验证完成签署的电子文件无效，证明文件信息已被篡改，篡改内容包括但不限于文件保存时间、文件修改时间、文件内容等信息。`
        }],

      }
    };
  },
  mounted() {
    this.changeTab();
  },
  methods: {
    reset() {
      this.changeTab();
    },
    search() {
      if (this.searchValue) {
        let list = JSON.parse(JSON.stringify(this.contents[this.activeName]));
        console.log(this.searchValue, "搜索");
        this.currentContent = this.fuzzyQuery(list, this.searchValue);
      }
    },
    changeTab() {
      this.currentContent = this.contents[this.activeName];
    },
    fuzzyQuery(list, key, attributes = ["title", "test"]) {
      const reg = new RegExp(key);
      let arr = [];
      for (var i = 0; i < attributes.length; i++) {
        for (var j = 0; j < list.length; j++) {
          let row = list[j];
          if (reg.test(row[attributes[i]])) {
            row[attributes[i]] = row[attributes[i]].replace(
              key,
              `<span style="color:#2979FF;">${key}</span>`
            );
            let length = arr.findIndex((item) => item.id == row.id);
            if (length < 0) {
              arr.push(row);
            }
          }
        }
      }
      return arr;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .content-weight {
  font-weight: 600;
  color: black;
}

::v-deep .el-collapse-item__content {
  padding-left: 18px;
  padding-right: 18px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  line-height: 24px;
}

.collapse-title {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 700;
  color: #333333;
  line-height: 38px;
}

.collapse-title-ico {
  width: 6px;
  height: 6px;
  background: #333333;
  border-radius: 50%;
  margin-right: 15px;
}

.main {
  margin-bottom: 100px;
}

.el-tabs {
  margin: 0 auto;
}

::v-deep .el-tabs__item {
  font-size: 24px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  line-height: 30px;
}

.title {
  text-align: center;
  margin-bottom: 40px;
}

.search {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-bottom: 40px;

  .el-input {
    width: 600px;
    height: 50px;
    border-radius: 8px;
  }

  .el-button {
    background: #0a4fd3;
    color: #fff;
    border-radius: 8px;
    width: 180px;
    height: 40px;
    margin-left: 20px;
  }
}
.help{
	text-align: center;
	margin-top: 135px
}
.help img{
	max-width: 100%;
}

@media screen and (max-width:720px) {
	.main {
	  margin-bottom: 50px;
	}
	.el-tabs {
	  width: 100%;
	}
	::v-deep .el-tabs__item {
	  font-size: 17px;
	  padding: 0 3px;
	  line-height: 30px;
	  font-weight: 600;
	}
	::v-deep .el-collapse-item {
	  padding-left: 15px;
	  padding-right: 15px;
	  font-weight: 400;
	  line-height: 24px;
	}
	.collapse-title {
	  font-size: 15px;
	  font-weight: 600;
	}
	.collapse-title-ico {
	  margin-right: 8px;
	}
	.search {
	  margin-bottom: 20px;
	  padding: 0 15px;
	  
	  .el-input {
	  
	  }
	
	  .el-button {
	    margin-left: 10px;
	  }
	}
	.help{
		margin-top: 65px;
		width: 100%;
		height: 180px;
		overflow: hidden;
		
		img{
			height: 100%;
			max-width: none;
			margin: -10px 0 -10px -20px;
		}
	}
}
</style>
<template>
	<div class="homepage">
		<div class="header">
			<div class="w">
				<div class="fl logo">
					<a href="./index.html">
						<img src="@/assets/images/bigguohui.png" :alt="`全国电子印章管理与服务平台${$areaName}平台`" />
						全国电子印章管理与服务平台{{$areaName}}平台
					</a>
				</div>
			</div>
		</div>

		<div class="banner">
			<div class="gif">
				<img class="default-pic" :src="homeBG" />
				<video id="video" :src="bannerVideo" class="video" autoplay muted loop></video>
				<dl class="words">
					<dt>
						<img src="@/assets/images/bigguohui.png" :alt="`全国电子印章管理与服务平台${$areaName}平台`" />
						<span>全国电子印章管理与服务平台{{$areaName}}平台</span>
					</dt>
					<dd>&nbsp;公安备案 全国互认 一键核验</dd>
				</dl>
			</div>
		</div>

		<verificationCenter />
		<problem/>
		
		<div class="tc footer">
			<div class="w">
				<div class="copyright">
					<b>技术支持：</b><span><a href="https://www.gaskmp.cn/" title="全国电子印章管理与服务平台" target="_blank"><img src="../assets/images/guohui.png" alt="全国电子印章管理与服务平台" />全国电子印章管理与服务平台</a></span>
					<i class="vertical-line"></i>
					<span><a href="https://www.gaskmp.cn/eseal/cooperation" title="公安部第三研究所" target="_blank"><img src="../assets/images/guohui.png" alt="公安部第三研究所" />公安部第三研究所</a></span>
					<span v-html="scCopyRight"></span>
				</div>
			</div>
		</div>
   
		<!-- <div :class="isShowHotline ? 'service-wrapper active' : 'service-wrapper'" @mouseenter="isShowHotline = true" @mouseleave="isShowHotline = false">
			<img :src="isShowHotline ? customerWhite : customerGray" />
			<p>联系我们</p>
			<div class="tips-frame">
				<img src="@/assets/images/tel_icon.png" />
				<div class="hotline">
					<p>24小时服务热线</p>
					<span>400-160-8685</span>
				</div>
			</div>
		</div> -->
	</div>
</template>
<script>
import verificationCenter from "../components/verificationCenter";
import problem from "../components/problem";
//import blockchainApplyData from "../components/blockchainApplyData";
export default {
	name: 'home',
	components: {
		verificationCenter,
    problem
	},
	data() {
		return {
			type: '01',
			data: [],
			unifiedBlockSealInfoAuthDtos: [],
			urlParam: '',
			urlVal: '',
			title: '',
			titleEN: '',
			totalDoc: 2,
			isShowHotline: false,
			homeBG: '',
			bannerVideo: '',
			customerGray: require('@/assets/images/customer_icon_gray.png'),
			customerWhite: require('@/assets/images/customer_icon_white.png'),
			sealStatusList: {
				'00': '制作中',
				'01': '正常 ',
				'02': '禁用',
				'03': '注销',
				'04': '删除',
				'05': '待审核制作',
				'06': '待启动制作',
				'07': '审核驳回'
			},
			qzSignStatusList: {
				'-1': '草稿',
				'0': '未签署',
				'1': '签署完成',
				'2': '签署中',
				'3': '撤回签署',
				'4': '拒绝签署',
				'5': '删除',
				'6': '即将过期',
				'7': '已过期'
			},
			authStatusList: {
				'0': '未认证',
				'1': '已认证 ',
				'2': '认证成功'
			},
			custCreditStatus: {
				'00': '待审核',
				'01': '通过 ',
				'02': '拒绝',
				'03': '冻结',
				'04': '注销'
			},
			scCopyRight: ''
		}
	},
	created() {
		Promise.all([
			import(`@/assets/images/${this.$areaCode}/home_bg.jpg`),
			import(`@/assets/images/${this.$areaCode}/banner.mp4`),
			// import(`@/assets/images/320000/home_bg.jpg`),
			// import(`@/assets/images/320000/banner.mp4`),
		]).then(res => {
			this.homeBG = res[0].default
			this.bannerVideo = res[1].default
		})
		if (this.$beianName) {
			this.scCopyRight = `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/#/Integrated/index" style="color: white;" target="_blank">${this.$beianName}</a>`
		}
	},
	mounted() {
		this.getUrlData();

	},
	methods: {
		getUrlData() {
			let geturl = window.location.href;
			let getqyinfo = geturl.split('?')[1];
			if (getqyinfo != undefined) {
				let getqyparam = getqyinfo.split('=')[0];
				let getqyval = getqyinfo.split('=')[1];
				this.urlParam = getqyparam;
				this.urlVal = getqyval;
			}
		}
	}
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.homepage {
	a {
		text-decoration: none;
		color: #666;
	}
	.tc {
		text-align: center;
	}
	.fl {
		float: left;
	}
	.fr {
		float: right;
	}
}

.header {
	height: 40px;
	padding: 10px 0;
	background: #fff;

	.logo {
		height: 40px;
		line-height: 40px;
		position: relative;
		font-size: 22px;
		font-weight: 500;
		overflow:hidden;
		
		a{
			color: #333;
		    display: flex;
		    align-items: center;
		    font-weight: bold;
			img{
				height: 40px;
				vertical-align: middle;
				margin-right: 14px;
				display: inline-block;
			}
		}
		span{
			font-size: 16px;
			font-weight: 400;
			color: #333333;
			margin-left: 20px;
		}
	}
}

.banner {
	.gif {
		height: 100%;
		width: 100%;
		position: relative;
		overflow: hidden;
		.default-pic {
			width: 100%;
		}
		.video {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			z-index: 999;
		}
		.words {
			z-index: 1000;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			dd{
				white-space: nowrap;
				margin-top: 30px;
				height: 35px;
				font-size: 36px;
				color: #FFFFFF;
				line-height: 40px;
				text-shadow: 0px 2px 10px rgba(3,45,144,0.4);
				letter-spacing: 48px;
				font-weight: 500;
				text-align: center;
			}
      dt{
        height: 110px;
        display: flex;
        align-items: center;
        font-size: 54px;
        font-weight: bold;
        color: #fff;
        letter-spacing: 3px;
		white-space: nowrap;
        img{
          height: 110px;
          margin-right: 30px;
        }
      }
		}
	}
}

.footer {
	height: 100px;
	line-height: 100px;
	background: #2C2E34;
	color: #999;
	font-size: 14px;
	
	b{
		font-weight: normal;
		margin-right: 10px;
	}
	span{
		font-weight: 500;
		color: #fff;
		a{
			color: #fff;
		}
		img{
			width: 18px;
			vertical-align: text-bottom;
			margin-right: 10px;
		}
	}

	.vertical-line {
		font-style: normal;
		margin: 0 15px;
	}
}
@media screen and (max-width:720px) {
	.header {
		padding: 5px 15px;
	
		.logo {
			font-size: 14px;
			a{
				img{
					height: 25px;
					margin-right: 8px;
				}
			}
		}
	}
	.banner {
		.gif {
			height: 180px;
			.default-pic {
				width: 100%;
				height: 100%;
			}
			.video {
				width: auto;
				height: 100%;
				display:none;
			}
			.words {
				dd{
					margin-top: 0;
					height: 35px;
					font-size: 15px;
					line-height: 35px;
					letter-spacing: 5px;
				}
			    dt{
					height: auto;
					flex-direction: column;
					font-size: 18px;
					letter-spacing: 0;
					img{
					  height: 56px;
					  display: block;
					  margin-right: 0;
					  margin-bottom: 10px;
					}
			    }
			}
		}
	}
	.footer {
		height: auto;
		line-height: 160%;
		padding: 10px 0;
		font-size: 12px;
		b{
			margin-right: 0;
		}
		span{
			a{
				font-weight: 400;
			}
			img{
				width: 18px;
				margin-right: 5px;
			}
		}
		span:last-child{
			display: block;
			margin: 5px 0 0 -10px;
		}
		.vertical-line {
			margin: 0 5px 0 0;
		}
	}
}

@media screen and (max-width:1499px) and (min-width:721px) {
	.banner {
		height: 420px;
		background-size: auto 100%;
		.gif {
			.words {
				dt {
					font-size: 42px;
				}

				dd {
					font-size: 32px;
					letter-spacing: 26px;
				}
			}
		}
	}
}
</style>

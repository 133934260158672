import Vue from 'vue'
import App from './App.vue'
import router from './router'
import SlideVerify from 'vue-monoplasty-slide-verify'
import Viewer from 'v-viewer'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'viewerjs/dist/viewer.css'
import "@/assets/css/common.css";
import { provinceGeo, realmNameDict,beianDict } from "@/utils/province.js";

let $areaCode = '520000'
const mark = origin.match(/cic-(.*?)\.com/);
if (mark) {
  $areaCode = realmNameDict[mark[1]]
}
// else if (document.cookie) {
//   let cookiePair = document.cookie.split('&')
//   let areacodeArr = cookiePair.filter(item => item.includes('areacode='))
//   if (areacodeArr.length > 0) {
//     $areaCode = areacodeArr[0].replace(/areacode=/g, '')
//   }
// }
Vue.prototype.$areaCode = $areaCode
Vue.prototype.$areaName = provinceGeo[$areaCode]
Vue.prototype.$beianName = beianDict[$areaCode] // 备案名称
Vue.config.productionTip = false

Vue.use(SlideVerify);
Vue.use(ElementUI);
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})

new Vue({
  router,
  render: h => h(App),
  mounted () { document.dispatchEvent(new Event('render-event'))}
}).$mount('#app')
